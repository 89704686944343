import { Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCurrentUser } from "features/Auth/authApi";
import { useDispatch } from "react-redux";
import { getReports } from "features/Rapports/rapportApi";
import ReportTable from "./ReportTable";
import { Report } from "features/Rapports/RapportType";


const AccueilPage = () => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const [reports, setReports] = useState<Report[]>([]);


  useEffect(() => {
    dispatch<any>(getReports(currentUser!.token, (data) => { setReports(data) }))
  }, []);

  return (
    <Container>
      <Grid item mb={2} xs={12}>
        <Typography variant="h4" letterSpacing={5} align="left" my={2} gutterBottom >
          Rapports
        </Typography>
        <Divider sx={{ backgroundColor: 'blue' }} />
      </Grid>
      <Grid item mt={5} xs={12}>
        <ReportTable reports={reports} />
      </Grid>
    </Container>
  );
}

export default AccueilPage;
