import IconButton from '@mui/material/IconButton';
import { AccountCircle, ExitToApp } from '@material-ui/icons';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCurrentUser, logout } from 'features/Auth/authApi';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Header: React.FC = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
        <Box textAlign="left" style={{ margin: 5, marginRight: 20 }}>
            <Typography variant='h4' style={{fontSize: '150%'}}>Lens-ship</Typography>
            <Typography variant='h5' style={{fontSize: '100%'}} fontWeight={"bold"}>État-Major</Typography>
          </Box>
          {user && (
            <>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              onClick={e => navigate("/")}
              sx={{ mx: 1, color: 'white', display: 'block' }}
            >
              {"Accueil"}
            </Button>
            { (user?.role === "admin") &&
              <Button
                onClick={e => navigate("/manageuser")}
                sx={{ mx: 1, color: 'white', display: 'block' }}
              >
                {"Gestion utilisateur"}
            </Button>
            }
            
          </Box>
          </>
          )}
          {user && (
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={e => navigate("/account")}
              >
                <AccountCircle />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={e => dispatch<any>(logout())}
              >
                <ExitToApp />
              </IconButton>
            </>
          )}

        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;