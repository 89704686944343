import { resultAnalyse, Report } from "./RapportType";
import { fetchApi } from "features/rest";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


  export const analyseImage = (imageData: File, token: string, funcSuccess: (data: resultAnalyse) => void) => async (dispatch: any) => {
    try {
      const formData = new FormData();
      formData.append('image', imageData);
  
      fetchApi({
        method: 'POST',
        endpoint: '/analyse',
        token: token,
        data: formData,
        funcSuccess: (responseData) => {
          funcSuccess(responseData);
        },
        dispatch
      });
    } catch (err) {
      toast.error('Erreur lors de l\'envoi de l\'image');
    }
  };

  export const saveImage = (imageData: File, token: string, funcSuccess: (data: any) => void) => async (dispatch: any) => {
    try {
      const formData = new FormData();
      formData.append('image', imageData);
  
      fetchApi({
        method: 'POST',
        endpoint: '/saveimage',
        token: token,
        data: formData,
        funcSuccess: (responseData) => {
          funcSuccess(responseData);
        },
        dispatch
      });
    } catch (err) {
      toast.error('Erreur lors de l\'envoi de l\'image');
    }
  };

  export const createReport = (data: any, token: string, funcSuccess: (data: any) => void) => async (dispatch: any) => {
    await fetchApi({
      method: 'POST',
      endpoint: '/createreport',
      token: token,
      data: data,
      funcSuccess: (data) => {
        funcSuccess(data);
      },
      dispatch
    });
  };

  export const getReports = (token: string, funcSuccess: (data: Report[]) => void) => async (dispatch: any) => {
    fetchApi({
      method: 'GET',
      endpoint: '/getreports',
      token: token,
      data: null,
      funcSuccess: (data) => {
        funcSuccess(data);
      },
      dispatch
    });
  };
