import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from "@mui/material";
import { Report } from "features/Rapports/RapportType";
import React from "react";

interface ModalReportProps {
    report: Report;
    open: boolean;
    onClose: () => void;
  }
  
  const ModalReport = (props: ModalReportProps) => {
    const {report,open,onClose} = props;
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6" color="black" align="center" sx={{ borderBottom: 1, borderColor: '#0000e0' }}>
          {report.ship.name} - {new Date(report.creationDate).toLocaleDateString('fr-FR')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
            <img
                src={report.imageUrl}
                alt={`Rapport #${report.id}`}
                style={{
                width: '80%',
                maxHeight: '200px',
                objectFit: 'contain',
                margin: '0 auto',
                display: 'block',
                }}
            />
          <Typography variant="body1" >
            <strong>Date de création:</strong> {new Date(report.creationDate).toLocaleDateString('fr-FR')}
          </Typography>
          <br />
          <Typography variant="body1" >
          <strong>GPS:</strong> {report.gps}
          </Typography>
          <br />
          <Typography variant="body1" >
          <strong>État:</strong> {report.state}
          </Typography>
          <br />
          <Typography variant="body1" >
          <strong>Description:</strong> {report.description}
          </Typography>
          <br />

          
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
    );
    };
  
  export default ModalReport;